.menu-button {
    display: inline-block;
    padding: 0.8em;
    background-color: @brand-blue__dark;
    border: 1px solid @brand-blue__dark;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    height: 60px;
    width: 60px;
    padding: 14px;
}

.burger-icon {
    .burger(100%, 1px, 7px, @brand-cream);
    margin-top: 19px;

}

.menu-button.is-active .burger-icon {
    .burger-to-cross(1px, 6px, @brand-cream);
    &:before {
        top: -4px;
    }
}


@media only screen and (min-device-width : 320px) and (max-device-width : 765px) and (orientation : portrait) {
    .menu-button {

        padding: 19px;
    }
    .burger-icon {
        margin-top: 15px;
    }
}