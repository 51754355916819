.back-to-top-wrapper {
  background-color: @brand-blue__dark;
  padding: 40px 0;
  .back-to-top {
    float: right;
    display: block;
    .text-button;
    font-size: 2em;
    margin-right: 40px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.thankyou {
  .back-to-top-wrapper {
    display: none;
  }
}