.menu-horizontal {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  text-align: center;
  li {
    display: inline-block;
    list-style: none;
      padding: 0;
    margin-right: 10px;
    text-transform: uppercase;
    a {
      transition: @menuAnimationTime @menuAnimationType;
      text-decoration: none;
      &:hover {
        transition: @menuAnimationTime @menuAnimationType;
        text-decoration: underline;
      }
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}



.menu-wrapper {
  position: fixed;
  width: 100%;
  height: 70px;
  padding: 30px;
  z-index: 30;
  .logo-wrapper {
    transition: @menuAnimationTime width @menuAnimationType;
    display: block;
    float: left;
    width: 410px;
    svg {
      transition: @menuAnimationTime width @menuAnimationType;
    }
  }
}


.menu-wrapper-filled, .property-info, .contact {
  background-color: @brand-green;
  .sans;
  .logo-wrapper {
    width: 20%;
    float: left;
    svg {
      width: 100%;
    }
    path {
      fill: @brand-cream;
    }
  }
  .menu-word {
    color: @brand-cream;
    letter-spacing: 3px;
  }
}


.property-info, .contact {
  .menu-wrapper {
    background-color: @brand-green;
  }
}

.menu-list {

}

.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.menu-word {
  float: right;
  color: @brand-blue__dark;
  letter-spacing: 3px;
  transition: @menuAnimationTime all  @menuAnimationType;
  font-weight: bold;
}

.menu-fullscreen {
  display: block;
  position: fixed;
  top: -110vh;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: @brand-blue__dark;
  transition: @menuAnimationTime all @menuAnimationType;
  z-index: 50;
  ul {
    margin: 0;
  }
  li {
    list-style: none;
    width: 100%;
    text-align: center;
    padding: 20px;
    font-weight: bold;
  }
  a {
    .text-button;
    font-size: 2em;
    letter-spacing: 2px;
  }
}

.menu-is-active {
  top: 0;
}