.attractions-block {
  width: 100%;
  padding: @spacing;
}

.attraction {
  float:left;
  width: 22.7%;
  margin-right: 3%;
  text-align: center;
  .attraction-image {
    width: 100%;
    margin-bottom: 20px;
  }
  .attraction-copy {
    p {
      .serif;
      font-size: 1.75rem;
    }
    margin:10px 0;
    min-height: 130px;
    padding: 0 20px;
  }
  h3,h4 {
    .sans;
    letter-spacing: 2px;
  }
  a {
    .text-button;
  }
}
.last-attraction {
  margin-right: 0;
}