.contact {
  p {
    &:last-of-type {
      margin-bottom: @spacing;
    }
  }
}

.form-block {
  width: 60%;
  h3 {
    margin-bottom: (@spacing / 2);
  }
}