// FONTS
@font-family-sans-serif:  'Poppins',"Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       'Prata', Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;



// COLOURS
@core-color__default:     #000000;

@core-dropkick-bg:        #d9d9d2;
@core-dropkick-col:       #000000;


@brand-green:             #2c3638;
@brand-blue__dark:        #251928;
@brand-cream:             #dccabb;

@spacing:                 80px;

@menuAnimationTime:      0.5s;
@menuAnimationType:      ease;

.serif {
  font-family: @font-family-serif;
}

.sans {
  font-family: @font-family-sans-serif;
  letter-spacing: 1px;
}

.image-caption {
  padding-top: 10px;
  font-size: 1rem;
}

.svg-samantha-logo {
  fill: @brand-blue__dark;
}
.all-caps {
  text-transform: uppercase;
}
.content-block, .price-block, .form-block {
  padding: @spacing;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  h3,h4 {
    .sans;
    margin-bottom: @spacing;
    margin-top: 0;
    letter-spacing: 7px;
    font-weight: 700;
  }
  p {
    .serif;
    letter-spacing: 1px;
    line-height: 2em;
    margin-bottom: 2em;
    font-size: 1.2rem;
    font-size: 1.75rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  img {
    width: 100%;
  }
  .folio-image {
    width: 50%;
  }
  .heading-half-bottom-margin {
    margin-bottom: (@spacing / 2);
  }
}

.content-block {
  // dougie request to make content wider
  width: 60%;
}
.form-block {
  width: 80%;
  padding: 100px 0;
}

.text-center {
  text-align: center;
}
.full-width-block {
 padding: @spacing;
 width: 100%;
 margin: 0 auto;
 img {
   width: 100%;
 }
}

.brand-line {
  border-color: @brand-cream;
  width: 100px;
  margin: 40px auto;
  display: block;
}

.text-button {
  color: @brand-cream;
  .all-caps;
  .sans;
  &:hover {
    color: @brand-cream;
  }
}
.external-link {
  .all-caps;
  .sans;
  color: @brand-cream;
  &:hover {
    color: @brand-cream;
    text-decoration: underline;
  }
}

.no-top-padding {
  padding-top: 0;
}

.no-bottom-padding {
  padding-bottom: 0;
}

.margin-above-text-link {
  margin-top: @spacing;
}

.font-700 {
  font-weight: 700;
}

.space-out {
  letter-spacing: 6px;
}
