@import (less) "normalize.css";
@import (less) "../node_modules/bootstrap/dist/css/bootstrap.css";

@import (less) "../recaptcha/recaptcha.css";

@import (less) "savi-core.less";

@import (less) "variables.less";
@import (less) "menu-horizontal.less";
@import (less) "../node_modules/less-burger/src/less/less-burger-menu.less";
@import (less) "../js/vendor/royalslider/royalslider.css";
@import (less) "../js/vendor/royalslider/skins/default/rs-default.css";

@import (less) "less-burger.less";
@import (less) "slider.less";
@import (less) "dropkick.less";
@import (less) "form.less";
@import (less) "price-content.less";
@import (less) "attraction-block.less";
@import (less) "book-content.less";
@import (less) "home.less";
@import (less) "back-to-top.less";

@import (less) "property-info.less";
@import (less) "contact.less";

body, html {
  background-color: @brand-green;
  color: @brand-cream;
}


.page-wrapper {
  min-width: 1480px;
}

.logo-wrapper {
  path {
    transition: all 1s ease;
  }
}


// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {

}

