.property-info-hero {
  background-image: url('../img/property-info/hero.jpg');
  height: 100vh;
  background-size: cover;
  background-position: center top;
  width: 100%;
  border-left: @spacing solid @brand-green;
  border-right: @spacing solid @brand-green;
}

.title-style {
  margin-bottom: @spacing;
  letter-spacing: 2px;
}
.pull-quote {
  letter-spacing: 2px;
  line-height: 2.8rem;

}
.content-block {
  .pull-quote {
    font-weight: 300;
    margin-bottom: 0;
  }
}

.pull-quote-author {
  .clearfix;
  .text-center;
  position: relative;
  margin: 0 auto;
  display: flex; justify-content: center;
  // width: 525px;
  // hr {
  //   float: left;
  // }
  h4, h5 {
    float: left;
    padding: 0 30px;
    margin-bottom: @spacing;
    padding-top: 22px;
    text-transform: uppercase;
  }
  .brand-line {
    margin-left:0;
    margin-right:0;
  }
}

.folio-image, .fiona-link {
  margin: @spacing  auto;
}
