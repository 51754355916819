.price-table {
  float: left;
  padding: 0 2vw;
  border-right: 1px solid @brand-cream;
  h4 {
    margin-bottom: 0px;
  }
  &:last-of-type {
    border-right: 0;
  }
}
.price-table-last {
  border-right: 0;
}

.price-table-wrapper {
  margin: 0 auto;
  width: 1302px;
  width: 1656px;
}

.price-block {
  width: 100%;
  p {
    .sans;
    margin-bottom: 0;
    &.serif {
      .serif;
    }
  }
  .price {
    .serif;
  }
}

.price-disclaimer {
  padding: 30px 0;
  border-top: 1px solid @brand-cream;
  border-bottom: 1px solid @brand-cream;
  margin: 30px auto;
}

.availability-terms-wrapper {
  padding-bottom:30px;
  border-bottom: 1px solid @brand-cream;
  margin-bottom: 30px;
}
.availability {
  float:left;
}

.rental-terms {
  float:right;
}
