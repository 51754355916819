.book-now-holder {
  width: 100%;
  text-align: center;
  margin: 30px auto;
  padding: @spacing;
  letter-spacing: 2px;

}
.book-now-internal {
  padding: @spacing 0;
  border-top: 1px solid @brand-cream;
  border-bottom: 1px solid @brand-cream;
  a {
    font-size: 1.8rem;
    font-weight: bold;
  }
}
