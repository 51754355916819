.homepage-hero {
  height: 100vh;
  background-image: url("../img/home/home-hero.jpg");
  background-size: cover;
  background-position: center top;
  width: 100%;
}
.smaller {
  text-align: center;
  padding: 0 20%;
}
.walk-holder {
  // margin-top: 100px;
}